import React from "react";
import "./OverlayedImage.css"


function OverlayedImage(props) {
    return (
        <div key={props.key} className={"OverlayedImage"} style={props.style}>
            <img src={props.src} alt={props.alt} style={props.imageStyle}/>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default OverlayedImage
