import React, {useEffect, useState} from "react";
import { ObserverGroup } from 'react-tweenful';
import "./ImageColumn.css"
import OverlayedImage from "./OverlayedImage";
import {Link} from "react-router-dom";

function ImageColumn(props) {
    const [rendered, setRendered] = useState([]);
    const [queue, setQueue] = useState([])

    useEffect(() => {
        setRendered([]);
        setQueue(props.images);
    }, [props.images]);

    useEffect(() => {
        if(queue.length > 0)
        {
            if(queue[0]["filename"].endsWith(".gif"))
            {
                setRendered(old => [...old, queue[0]]);
                setQueue(old => old.slice(1));
                return
            }

            const download = new Image();
            download.onload = () =>
            {
                setRendered(old => [...old, queue[0]]);
                setQueue(old => old.slice(1));
            }
            download.src = process.env.REACT_APP_VAULT_ENDPOINT+"/content/file/" + queue[0]["filename"];
        }
    }, [queue]);

    /*
    useEffect(() => {
        //setRendered([]);
        for(let i = 0; i < props.images.length; i++)
        {
            const download = new Image();
            download.onload = () => {
                setRendered(old => [...old, props.images[i]]);
            }
            download.src = "http://localhost:5000/content/file/"+props.images[i]["filename"];
        }
    }, [props.images])*/

    return (
        <div className={"ImageColumn"}>
            <ObserverGroup
                config={{
                    duration: 800,
                    mount: { opacity: [0, 1] },
                    unmount: { opacity: [1, 0] },
                    easing: 'easeInOutCubic'
                }}
                skipInitial={true}
            >
                {rendered.map(image => (
                    <OverlayedImage key={image["id"]} src={process.env.REACT_APP_VAULT_ENDPOINT+"/content/file/"+image["filename"]} alt={"whatever"} imageStyle={{
                        width: "100%",
                        margin: "1px 0"
                    }}>
                        <div className={"ImageColumn-Overlay"}>
                            <span>{(image["name"].length > 30 ? image["name"].substr(0, 28) + ".." : image["name"])}</span>
                            <Link to={"/meme/"+image["id"]}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                </svg>
                            </Link>
                        </div>
                    </OverlayedImage>
                ))}
            </ObserverGroup>
        </div>
    );
}

export default ImageColumn;
