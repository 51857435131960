import React from "react";
import "./Images.css";
import ImageColumn from "./ImageColumn";

const COLUMNS = 3;

function Images(props) {
    const split = [];
    for(let i = 0; i < COLUMNS; i++) split.push([])
    for(let i = 0; i < props.images.length; i++) split[i % COLUMNS].push(props.images[i])

    return (
        <div className={"Images"}>
            {split.map((images, index) => (
                <ImageColumn key={index} images={images}/>
            ))}
        </div>
    );
}

export default Images;
