import React from "react";
import './App.css';
import { ObserverGroup, Observer } from 'react-tweenful';
import {Switch, Route, useLocation, Link} from "react-router-dom";
import HelpPage from "./Pages/HelpPage";
import SearchPage from "./Pages/SearchPage";
import MemePage from "./Pages/MemePage";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <div className="App-Content">
                <div className="App-Nav">
                    <div>
                        <Link to={"/"}>
                            <div className={"App-Nav-Link"}>Home</div>
                        </Link>
                    </div>
                    <div>
                        <h1 className={"App-Title"}>memefind</h1>
                    </div>
                    <div>
                        <Link to={"/help"}>
                            <div className={"App-Nav-Link"}>Help</div>
                        </Link>
                    </div>
                </div>
                <div className="App-Page">
                    <ObserverGroup>
                        <Observer.div
                            key={location.pathname}
                            duration={300}
                            style={{ opacity: 0 }}
                            mount={{ opacity: 1 }}
                            unmount={{ opacity: 0 }}
                            easing={"easeOutQuad"}
                        >
                            <Switch location={location}>
                                <Route path="/help" component={HelpPage} />
                                <Route path={"/meme/:id"} component={MemePage} />
                                <Route path={["/search/:tags?", "/"]} component={SearchPage} />
                                <Route render={() => <div>Not Found</div>} />
                            </Switch>
                        </Observer.div>
                    </ObserverGroup>
                </div>
            </div>
        </div>
    );
}

export default App;
