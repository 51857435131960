import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import Images from "../Components/Images";
import "./SearchPage.css";

function SearchPage(props) {
    const [searchValue, setSearchValue] = useState((props.match.params.tags || "").replace("+", " "));
    const [imagesElement, setImagesElement] = useState(<Images images={[]}/>);

    useEffect(() => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                const result = JSON.parse(this.responseText);
                if(result["success"] === true)
                {
                    setImagesElement(<Images images={result["data"]}/>);
                }
                else console.log("dood");
            }
        };
        if(props.match.params.tags) xmlhttp.open("GET", process.env.REACT_APP_VAULT_ENDPOINT+"/api/memes/list/tags/"+(props.match.params.tags || "").replace(" ", "+"), true);
        else xmlhttp.open("GET", process.env.REACT_APP_VAULT_ENDPOINT+"/api/memes/list", true);
        setTimeout(() => xmlhttp.send(), 10);
    }, [props.match.params.tags]);

    const history = useHistory();
    const onKeyDown = (event) => {
        if(event.key === "Enter") history.push("/search/"+event.target.value.replace(" ", "+"));
    }
    const onSearchChange = (event) => {
        setSearchValue(event.target.value);
    }

    return (
        <div className={"Search"}>
            <input type="text" placeholder="Search on tags like 'doge' or 'dame_da_ne'.." value={searchValue} className={"Search-Input"} onChange={onSearchChange} onKeyDown={onKeyDown}/>
            {imagesElement}
        </div>
    );
}

export default SearchPage;
