import React, {useState, useEffect} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Images from "../Components/Images";
import "./MemePage.css"

function MemePage(props) {
    const [meme, setMeme] = useState(undefined);
    const [images, setImages] = useState(undefined);
    const [error, setError] = useState("");

    useEffect(() => {
        setMeme(undefined);
        setImages(undefined);
        setError("");

        const xmlhttp = new XMLHttpRequest();
        const xmlhttp2 = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function() {
            if (this.readyState === 4) {
                if(this.status === 200) {
                    const memeresult = JSON.parse(this.responseText);
                    if(memeresult["success"] === true)
                    {
                        if(memeresult["data"])
                        {
                            setMeme(memeresult["data"]);
                            xmlhttp2.onreadystatechange = function() {
                                if(this.readyState === 4)
                                {
                                    if(this.status === 200)
                                    {
                                        const imagesresult = JSON.parse(this.responseText);
                                        if(imagesresult["success"] === true)
                                        {
                                            if(imagesresult["data"]) setImages(imagesresult["data"]);
                                            else setError("Meme not found!");
                                        }
                                        else switch(imagesresult["error"])
                                        {
                                            case "ILLEGAL_ID":
                                                setError("Could not fetch images: Illegal meme ID (Are you sure it's a number?)");
                                                break;
                                            case "INVALID_ID":
                                                setError("Could not fetch images: Invalid meme ID");
                                                break;
                                            default:
                                                setError(memeresult["error"]);
                                                break;
                                        }
                                    }
                                    else setError("Could not fetch images: HTTP "+this.status);
                                }
                            };
                            xmlhttp2.open("GET", process.env.REACT_APP_VAULT_ENDPOINT+"/api/images/meme/"+props.match.params.id, true);
                            xmlhttp2.send();
                        }
                        else setError("Meme not found!");
                    }
                    else switch(memeresult["error"])
                    {
                        case "ILLEGAL_ID":
                            setError("Could not fetch meme: Illegal meme ID (Are you sure it's a number?)");
                            break;
                        case "INVALID_ID":
                            setError("Could not fetch meme: Invalid meme ID");
                            break;
                        default:
                            setError(memeresult["error"]);
                            break;
                    }
                }
                else setError("Could not fetch meme: HTTP "+this.status);
            }
        };
        xmlhttp.open("GET", process.env.REACT_APP_VAULT_ENDPOINT+"/api/meme/id/"+props.match.params.id, true);
        setTimeout(() => xmlhttp.send(), 10);
        return () => {
            xmlhttp.abort();
            xmlhttp2.abort();
        }
    }, [props.match.params.id]);

    return (
        <div>
            <SwitchTransition>
                <CSSTransition
                    key={error}
                    timeout={300}
                    classNames='MemePage-Fade'
                >
                    { error ?
                        <p>{error}</p>
                        :
                        <SwitchTransition>
                            <CSSTransition
                                key={typeof meme !== "undefined"}
                                timeout={300}
                                classNames='MemePage-Fade'
                            >
                                { !meme ?
                                    <div className={"MemePage-Loading"}>
                                        <div className={"App-Loader"}>Loading...</div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="MemePage-Title">
                                            <h1>{meme["name"]}</h1>
                                        </div>
                                        <SwitchTransition>
                                            <CSSTransition
                                                key={typeof images !== "undefined"}
                                                timeout={300}
                                                classNames='MemePage-Fade'
                                            >
                                                { !images ?
                                                    <div className={"MemePage-Loading"}>
                                                        <div className={"App-Loader"}>Loading...</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        { images.length === 0 && <p>Seems like there aren't any images for this meme! It'll probably get deleted soon.</p> }

                                                        {
                                                            images.filter((e) => e["variation"] === 0).map((e) => <img key={e["id"]} src={process.env.REACT_APP_VAULT_ENDPOINT+"/content/file/"+e["filename"]} alt="meme" className="MemePage-Meme" />)
                                                        }

                                                        <br/><br/>
                                                        <Images images={images.filter((e) => e["variation"] === 1)} />
                                                    </div>
                                                }
                                            </CSSTransition>
                                        </SwitchTransition>
                                    </React.Fragment>
                                }
                            </CSSTransition>
                        </SwitchTransition>
                    }
                </CSSTransition>
            </SwitchTransition>
        </div>

    );
}

export default MemePage;
